import { GetAxios } from "@/api/AxiosGet"; //get请求
import { PostAxios } from "@/api/AxiosPost"; //post请求
import {
  menuPower,
  menuList,
  AddMenu,
  handleMenu,
  bottonList,
  addBotton,
  handleBotton,
  roleList,
  adminList,
  allAuth,
  adminMenuIds,
  consultRole,
  roleHandle,
  history,
  register,
  CanDistributionRole,
  v2register,
  edit,
  createRole,
  roleInfo,
  roleEdit,
  roleAdmin,
  roleDel,
  roleTransfer,
  selectPlatformMechanism,
  addPlatformMechanism,
  deleteMechanism,
  selectPlatformStaff,
  renamingPlatform,
  movePlatform,
  allAdmin,
  addPlatformStaff,
  selectDetailsStaff,
  updatePlatformStaff,
  disableEnable,
  deleteStaff,
  AdminDelete,
  supplierMenuList,
  supplierMenuAdd,
  supplierMenuEdit,
  supplierMenuDel,
  supplierMenuHidden,
  platformMenuList,
  platformMenuAdd,
  platformMenuAddEdit,
  platformMenuAddSwitchHidden,
  platformMenuAddDel,
  appUpgradeCreate,
  recordList
} from "@/api/phpUrl"; //引入接口
import router from "@/router";

export default {
  namespaced: true,
  // 集中数据
  state: {
    Menu: [],
    RolaListPage:1
  },
  // 处理state中的数据
  mutations: {
    // 获取菜单列表
    GetPower(state, data) {
      state.Menu = data.data;
    },
    cgRolaListPage(state, data){
      state.RolaListPage = data;
    }
  },
  // 发送异步请求
  actions: {
    // 拿取菜单权限列表
    async getMenuList(context) {
      let data = await GetAxios(menuPower);
      if (data.code == 200 && data.data.length > 0) {
        context.commit("GetPower", data);
      } else if (data.code == 8000 || data.data.length == 0) {
        router.push({ path: "/notPower" });
      }
    },
    // 拿取权限列表里的菜单列表
    async getRootMenuList(context, params) {
      return await GetAxios(menuList, params);
    },
    // 新建菜单
    async PostAddMenu(context, params) {
      return await PostAxios(AddMenu, params);
    },
    // 修改编辑菜单
    async PostHandleMenu(context, params) {
      return await PostAxios(handleMenu, params);
    },
    // 获取子级菜单列表
    async getBottonList(context, params) {
      return await GetAxios(bottonList, params);
    },
    // 新建按钮
    async postAddBotton(context, params) {
      return await PostAxios(addBotton, params);
    },
    // 修改按钮
    async postHandleBotton(context, params) {
      return await PostAxios(handleBotton, params);
    },
    //获取角色列表
    async getroleList(context, params) {
      return await GetAxios(roleList, params);
    },
    //获取当前账号可分配的权限
    async getCanDistributionRole(context, params) {
      return await GetAxios(CanDistributionRole, params);
    },
    // 创建账号
    async postv2register(context, params) {
      return await PostAxios(v2register, params);
    },
    //   编辑角色
    async postedit(context, params) {
      return await PostAxios(edit, params);
    },
    //查询角色
    async getedit(context, params) {
      return await GetAxios(edit, params);
    },
    //  删除平台账号
    async postAdminDelete(context, params) {
      return await PostAxios(AdminDelete, params);
    },
    // // 获取后台人员列表
    // async getadminList(context, params) {
    //     return await PostAxios(adminList, params)
    // },
    //获取后台人员列表
    async getadminList(context, params) {
      return await GetAxios(adminList, params);
    },
    // 获取所有权限列表
    async getallAuth(context, params) {
      return await GetAxios(allAuth, params);
    },
    // 获取当前账户可查看的参考角色
    async getconsultRole(context, params) {
      return await GetAxios(consultRole, params);
    },
    // 获取指定管理员账户权限菜单id集合
    async getadminMenuIds(context, params) {
      return await GetAxios(adminMenuIds, params);
    },
    // 权限新增编辑
    async postRoleHandle(context, params) {
      return await PostAxios(roleHandle, params);
    },
    // 父哦去后台操作记录
    async posthistory(context, params) {
      return await PostAxios(history, params);
    },
    // 新建后台人员
    //
    async postregister(context, params) {
      return await PostAxios(register, params);
    },
    //创建角色
    async postCreateRole(context, params) {
      return await PostAxios(createRole, params);
    },
    //编辑角色
    async postroleEdit(context, params) {
      return await PostAxios(roleEdit, params);
    },
    //角色详情
    async getroleInfo(context, params) {
      return await GetAxios(roleInfo, params);
    },
    //获取角色下管理员账号
    async getroleAdmin(context, params) {
      return await GetAxios(roleAdmin, params);
    },
    //删除角色
    async postroleDel(context, params) {
      return await PostAxios(roleDel, params);
    },
    //转移角色下账号
    async postroleTransfer(context, params) {
      return await PostAxios(roleTransfer, params);
    },
    //获取员工列表
    async postselectPlatformStaff(context, params) {
      return await PostAxios(selectPlatformStaff, params);
    },
    //获取机构列表
    async postselectPlatformMechanism(context, params) {
      return await PostAxios(selectPlatformMechanism, params);
    },
    //新增机构
    async postaddPlatformMechanism(context, params) {
      return await PostAxios(addPlatformMechanism, params);
    },
    //删除机构
    async getdeleteMechanism(context, params) {
      return await GetAxios(deleteMechanism, params);
    },
    //机构重名命
    async postrenamingPlatform(context, params) {
      return await PostAxios(renamingPlatform, params);
    },
     //移动机构
     async postmovePlatform(context, params) {
      return await PostAxios(movePlatform, params);
    },
     //获取所有下级管理员包括自己
     async getallAdmin(context, params) {
      return await GetAxios(allAdmin, params);
    },
    //移动机构
    async postaddPlatformStaff(context, params) {
      return await PostAxios(addPlatformStaff, params);
    },
    //查询员工详情
    async getselectDetailsStaff(context, params) {
      return await GetAxios(selectDetailsStaff, params);
    },
    //编辑员工
    async postupdatePlatformStaff(context, params) {
      return await PostAxios(updatePlatformStaff, params);
    },
    //禁用/启用员工
    async getdisableEnable(context, params) {
      return await GetAxios(disableEnable, params);
    },
    //删除员工
    async getdeleteStaff(context, params) {
      return await GetAxios(deleteStaff, params);
    },

    // 权限菜单列表
    async getSupplierMenuList(context, params) {
      return await GetAxios(supplierMenuList, params);
    },
      // 权限菜单列表新增
    async postSupplierMenuAdd(context, params) {
      return await PostAxios(supplierMenuAdd, params);
    },
    //权限菜单编辑
    async postSupplierMenuEdit(context, params) {
      return await PostAxios(supplierMenuEdit, params);
    },
    //权限菜单删除
    async postSupplierMenuDel(context, params) {
      return await PostAxios(supplierMenuDel, params);
    },
    //权限菜单开关
    async postSupplierMenuHidden(context, params) {
      return await PostAxios(supplierMenuHidden, params);
    },
    //新平台菜单-列表
    async platformMenuList(context, params) {
      return await GetAxios(platformMenuList, params);
    },
    //新平台菜单-新建菜单
    async platformMenuAdd(context, params) {
      return await PostAxios(platformMenuAdd, params);
    },
    //新平台菜单-编辑菜单
    async platformMenuAddEdit(context, params) {
      return await PostAxios(platformMenuAddEdit, params);
    },
    //新平台菜单-显示/隐藏
    async platformMenuAddSwitchHidden(context, params) {
      return await PostAxios(platformMenuAddSwitchHidden, params);
    },
    //新平台菜单-删除
    async platformMenuAddDel(context, params) {
      return await PostAxios(platformMenuAddDel, params);
    },
    //App更新说明-新增
    async appUpgradeCreate(context, params) {
      return await PostAxios(appUpgradeCreate, params);
    },
    //App更新说明-更新记录
    async recordList(context, params) {
      return await GetAxios(recordList, params);
    },
  },
};
