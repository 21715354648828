import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "element-ui"; //引入elmentui提示框
import Login from "../views/Login/login.vue";
import Home from "../views/Home/wholeHome.vue"; //全局主体框架
import contentHome from "../views/Home/contentHome.vue"; //后台首页
import notPower from "../views/Home/notPower.vue"; //无访问权
import { getToken } from "@/utils/auth";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "登录",
    component: Login,
  },
  {
    path: "/Home",
    name: "首页",
    component: Home,
    redirect: "/contentHome",
    children: [
      // 中间页
      {
        path: "/contentHome",
        component: contentHome,
        name: "后台首页",
      },
      {
        path: "/refresh",
        component: () => import("../views/Home/refresh.vue"),
        name: "刷新数据",
      },
      {
        path: "/amendPwd",
        component: () => import("../views/Home/amendPwd.vue"),
        name: "修改密码",
      },
      {
        path: "/notPower",
        component: notPower,
        name: "无访问权限",
      },
      {
        path: "/merchanQLiFicaTionList",
        component: () =>
          import("../views/RootAdmin/Aptitude/merchanQLiFicaTionList.vue"),
        name: "供应商资质",
      },
      {
        path: "/BackMenuList",
        component: () => import("../views/RootAdmin/backMenuList.vue"),
        name: "平台菜单",
      },
      {
        path: "/PermissionsMenuList",
        component: () => import("../views/RootAdmin/PermissionsMenuList.vue"),
        name: "供应商权限菜单",
      },
      {
        path: "/systemPage",
        component: () => import("../views/RootAdmin/systemPage.vue"),
        name: "系统页面",
      },
      // commConfiguration
      {
        path: "/commConfiguration",
        component: () => import("../views/RootAdmin/commConfiguration.vue"),
        name: "业务员佣金配置",
      },

      {
        path: "/ControlRecord",
        component: () => import("../views/RootAdmin/controlRecord.vue"),
        name: "操作记录",
      },

      {
        path: "/userQuaLiFicaTionList",
        component: () =>
          import("../views/RootAdmin/Aptitude/userQuaLiFicaTionList.vue"),
        name: "会员资质",
      },
      {
        path: "/checkQuaList",
        component: () =>
          import("../views/RootAdmin/Aptitude/enterprise/checkQuaList.vue"),
        name: "企业资质",
      },
      {
        path: "/agreement",
        component: () => import("../views/RootAdmin/agreement/agreement.vue"),
        name: "协议中心",
      },
      {
        path: "/manageType",
        component: () => import("../views/RootAdmin/manageType/manageType.vue"),
        name: "经营类别",
      },
      {
        path: "/RolaList",
        component: () => import("../views/platformAdmin/rolaList.vue"),
        name: "角色管理",
        // meta: {
        //   keepAlive: true, // 开启缓存
        // },
      },
      {
        path: "/permission",
        component: () => import("../views/platformAdmin/permission.vue"),
        name: "角色权限",
      },
      {
        path: "/BackPerson",
        component: () => import("../views/platformAdmin/backPerson.vue"),
        name: "账号管理",
      },
      {
        path: "/organization",
        component: () => import("../views/platformAdmin/organization.vue"),
        name: "机构与员工管理",
      },
      {
        path: "/RedactBtn",
        component: () => import("../views/RootAdmin/redactBtn.vue"),
        name: "平台按钮权限列表",
      },
      {
        path: "/merchantCenterList",
        component: () => import("../views/merchantAudit/merchantCenterList"),
        name: "供应商列表",
      },

      {
        path: "/ERPVerify",
        component: () => import("../views/merchantAudit/ERPVerify"),
        name: "ERP信息对码",
      },
      {
        path: "/cloudGood",
        component: () => import("../views/goodAdmin/cloudGood.vue"),
        name: "云仓库商品库",
      },
      {
        path: "/forbidSell",
        component: () => import("../views/goodAdmin/forbidSell.vue"),
        name: "商品禁售清单",
      },
      {
        path: "/cloudGoodMessage",
        component: () => import("../views/goodAdmin/cloudGoodMessage.vue"),
        name: "云仓库详情",
      },
      {
        path: "/DatabaseUpdateSet",
        component: () => import("../views/goodAdmin/DatabaseUpdateSet.vue"),
        name: "资料库更新设置",
      },
      {
        path: "/addGood",
        component: () => import("../views/goodAdmin/goodMessage/addGood.vue"),
        name: "新建商品详情",
      },
      {
        path: "/GoodFiling",
        component: () =>
          import("../views/goodAdmin/goodMessage/GoodFiling.vue"),
        name: "申请建档详情",
      },
      {
        path: "/redactGood",
        component: () =>
          import("../views/goodAdmin/goodMessage/redactGood.vue"),
        name: "编辑商品详情",
      },
      {
        path: "/goodsAudit",
        component: () => import("../views/audit/goodsAudit/goodsAudit.vue"),
        name: "商家入驻审核",
      },
      // withdrawalReview
      {
        path: "/withdrawalReview",
        component: () =>
          import("../views/audit/withdrawalReview/withdrawalReview.vue"),
        name: "佣金提现审核",
      },
      {
        path: "/WithdrawalDetails",
        component: () =>
          import("../views/audit/withdrawalReview/WithdrawalDetails.vue"),
        name: "佣金提现审核明细",
      },
      {
        path: "/userAudit",
        component: () => import("../views/audit/userAudit/userAudit.vue"),
        name: "会员审核",
      },
      {
        path: "/approvedMemo",
        component: () => import("../views/audit/approvedMemo.vue"),
        name: "审核记录",
      },
      {
        path: "/goodsCheck",
        component: () => import("../views/audit/merchAudit/goodsCheck.vue"),
        name: "商品审核",
      },
      {
        path: "/platQualification",
        component: () => import("../views/audit/platQualification/platQualification.vue"),
        name: "平台资质审核",
      },
      {
        path: "/platGoodication",
        component: () => import("../views/audit/platQualification/platGoodication.vue"),
        name: "平台商品资质审核",
      },
      {
        path: "/platQualifidetails",
        component: () => import("../views/audit/platQualification/platQualifidetails.vue"),
        name: "平台商品资质详情",
      },
      {
        path: "/merchantMessage",
        component: () => import("../views/merchantAudit/merchantMessage.vue"),
        name: "供应商详情",
      },
      {
        path: "/changmerchantMessage",
        component: () =>
          import("../views/merchantAudit/changmerchantMessage.vue"),
        name: "供应商编辑",
      },
      {
        path: "/detailsView",
        component: () =>
          import("../views/merchantAudit/detailsView.vue"),
        name: "往期资质详情",
      },
      {
        path: "/activityAudit",
        component: () =>
          import("../views/audit/activityAudit/activityAudit.vue"),
        name: "活动商品审核",
      },
      {
        path: "/activityDetails",
        component: () =>
          import("../views/audit/activityAudit/activityDetails.vue"),
        name: "活动商品审核",
      },
      //
      {
        path: "/usersCenterList",
        component: () => import("../views/users/usersCenterList.vue"),
        name: "会员中心",
      },
      {
        path: "/usersRedact",
        component: () => import("../views/users/usersRedact.vue"),
        name: "编辑会员信息",
      },
      {
        path: "/usersAccount",
        component: () => import("../views/users/usersAccount.vue"),
        name: "会员账户",
      },
      {
        path: "/promptlyAudit",
        component: () => import("../views/audit/goodsAudit/promptlyAudit.vue"),
        name: "入驻审核",
      },
      {
        path: "/Regisreviewdetails",
        component: () => import("../views/audit/goodsAudit/Regisreviewdetails.vue"),
        name: "入驻审核详情",
      },
      {
        path: "/usersMessage",
        component: () => import("../views/audit/userAudit/usersMessage.vue"),
        name: "会员审核",
      },
      {
        path: "/userdetails",
        component: () =>
          import("../views/audit/userAudit/AuditMessage/userdetails.vue"),
        name: "会员审核详情",
      },
      {
        path: "/toexamine",
        component: () =>
          import("../views/audit/userAudit/AuditMessage/toexamine.vue"),
        name: "立即审核",
      },
      //
      {
        path: "/orderCenterList",
        component: () => import("../views/order/orderCenterList"),
        name: "订单中心",
        meta: {
          keepAlive: true, // 开启缓存
        },
      },
      //
      {
        path: "/detailsedes",
        component: () => import("../views/order/detailsedes"),
        name: "订单详情",
      },
      {
        path: "/aftersales",
        component: () => import("../views/order/aftersales.vue"),
        name: "售后订单",
      },
      {
        path: "/orderDetails",
        component: () => import("../views/order/orderDetails.vue"),
        name: "售后订单详情",
      },
      {
        path: "/orderWarning",
        component: () => import("../views/order/orderWarning.vue"),
        name: "订单预警",
      },
      {
        path: "/requestNotes",
        component: () => import("../views/order/requestNotes.vue"),
        name: "自动请货申请单",
      },
      {
        path: "/requestNotesDetails",
        component: () => import("../views/order/requestNotesDetails.vue"),
        name: "采购申请单",
      },
      {
        path: "/areaList",
        component: () => import("../views/RootAdmin/areaList"),
        name: "省市区配置",
      },
      {
        path: "/logistics",
        component: () => import("../views/RootAdmin/logistics/logistics"),
        name: "物流公司",
      },
      {
        path: "/commission",
        component: () => import("../views/RootAdmin/commission/commission"),
        name: "免息期和白条佣金",
      },
      {
        path: "/dataBank",
        component: () =>
          import("../views/audit/merchAudit/merchMessage/dataBank"),
        name: "商品审核",
      },
      {
        path: "/basicMessage",
        component: () => import("../views/audit/merchAudit/merchMessage/ShAll"),
        name: "商品审核",
      },
      {
        path: "/goodsAClassify",
        component: () =>
          import("../views/goodAdmin/goodsAClassify/goodsAClassify"),
        name: "商品分类",
      },

      {
        path: "/Goodpoints",
        component: () => import("../views/goodAdmin/goodMessage/Goodpoints"),
        name: "修改分佣",
      },
      {
        path: "/goodsLibrary",
        component: () => import("../views/goodAdmin/goodsLibrary"),
        name: "商品资料库",
      },
      {
        path: "/Batimportadd",
        component: () => import("../views/goodAdmin/Batimportadd"),
        name: "代供应商导入",
      },
      {
        path: "/goodshared",
        component: () => import("../views/goodAdmin/goodMessage/goodshared"),
        name: "审核共享商品",
      },
      {
        path: "/goodsharing",
        component: () => import("../views/goodAdmin/goodsharing"),
        name: "云仓共享商品",
      },
      {
        path: "/goodMessage",
        component: () => import("../views/goodAdmin/goodMessage/goodMessage"),
        name: "标准商品库",
      },
      {
        path: "/goodsStandard",
        component: () => import("../views/goodAdmin/goodsStandard/goodsStandard"),
        name: "标准商品库",
      },
      {
        path: "/factory",
        component: () => import("../views/RootAdmin/factory/factory"),
        name: "厂家证明材料",
      },
      {
        path: "/bank",
        component: () => import("../views/RootAdmin/bank"),
        name: "银行管理",
      },
      {
        path: "/refundSlip",
        component: () => import("../views/closingAdmin/refundSlip/refundSlip"),
        name: "退款单",
      },
      {
        path: "/refundInfo",
        component: () => import("../views/closingAdmin/refundSlip/refundInfo"),
        name: "退款明细",
      },
      // {
      //   path: '/billManagement', component: () => import("../views/closingAdmin/billManagement/billManagement"), name: "账单管理"
      // },
      // {
      //   path: '/billDetails', component: () => import("../views/closingAdmin/billManagement/billDetails"), name: "账单详情"
      // },
      {
        path: "/paymentRecord",
        component: () =>
          import("../views/closingAdmin/paymentRecord/paymentRecord"),
        name: "打款记录",
      },
      // {
      //   path: '/withdrawalRecord', component: () => import("../views/closingAdmin/withdrawalRecord/withdrawalRecord"), name: "提现记录"
      // },
      {
        path: "/markcon",
        component: () => import("../views/merchantAudit/markcon/markcon"),
        name: "分账配置",
      },
      {
        path: "/outdata",
        component: () => import("../views/RootAdmin/outdata/outdata"),
        name: "兜底数据",
      },
      {
        path: "/defaultStore",
        component: () => import("../views/RootAdmin/defaultStore/defaultStore"),
        name: "默认店铺配置",
      },
      //
      {
        path: "/accountQuery",
        component: () => import("../views/settlementCenter/accountQuery.vue"),
        name: "台账查询",
      },
      {
        path: "/refundRequestEnquiry",
        component: () =>
          import("../views/settlementCenter/refundRequestEnquiry.vue"),
        name: "退款单查询",
      },
      {
        path: "/centCommission",
        component: () => import("../views/settlementCenter/centCommission.vue"),
        name: "分佣查询",
      },
      {
        path: "/salecommission",
        component: () => import("../views/settlementCenter/salecommission.vue"),
        name: "业务员佣金",
      },
      {
        path: "/giveawayStatistics",
        component: () =>
          import("../views/settlementCenter/giveawayStatistics.vue"),
        name: "分佣统计",
      },
      {
        path: "/billManagement",
        component: () => import("../views/settlementCenter/billManagement.vue"),
        name: "账单查询",
      },
      {
        path: "/billManagementDetails",
        component: () =>
          import("../views/settlementCenter/billManagementDetails.vue"),
        name: "账单详情",
      },
      {
        path: "/moneyRecord",
        component: () => import("../views/settlementCenter/moneyRecord.vue"),
        name: "打款记录",
      },
      {
        path: "/withdrawalRecord",
        component: () =>
          import("../views/settlementCenter/withdrawalRecord.vue"),
        name: "提现记录",
      },
      {
        path: "/wholedeploy",
        component: () => import("../views/RootAdmin/wholedeploy/wholedeploy"),
        name: "全局配置",
      },
      {
        path: "/upgrade",
        component: () => import("../views/RootAdmin/upgrade/upgrade"),
        name: "助手APP升级说明",
      },
      {
        path: "/cedingGoods",
        component: () => import("../views/goodAdmin/goodMessage/cedingGoods"),
        name: "修改分佣",
      },
      {
        path: "/PinAnmemberManagement",
        component: () => import("../views/finance/PinAnmemberManagement"),
        name: "药聚力白条会员管理",
      },
      {
        path: "/PinAnloanDocumentation",
        component: () => import("../views/finance/PinAnloanDocumentation"),
        name: "药聚力白条贷款记录",
      },
      {
        path: "/memberLoanRecord",
        component: () => import("../views/finance/memberLoanRecord"),
        name: "会员贷款记录",
      },
      {
        path: "/loanDetails",
        component: () => import("../views/finance/loanDetails"),
        name: "药聚力白条款详情",
      },
      {
        path: "/complainsRecords",
        component: () => import("../views/merchantAudit/complainsRecords"),
        name: "投诉记录",
      },
      {
        path: "/announcementList",
        component: () =>
          import("../views/audit/announcement/announcementList.vue"),
        name: "公告审核",
      },
      {
        path: "/announcementDetail",
        component: () =>
          import("../views/audit/announcement/announcementDetail.vue"),
        name: "公告审核详情",
      },
      {
        path: "/whitecustomers",
        component: () =>
          import("../views/WhiteBarManage/whitecustomers/whitecustomers.vue"),
        name: "白条管理",
      },
      {
        path: "/DetInformation",
        component: () =>
          import("../views/WhiteBarManage/whitecustomers/DetInformation.vue"),
        name: "客户资料",
      },
      {
        path: "/auditGoodsFile",
        component: () =>
          import("../views/audit/auditGoodsFile/auditGoodsFile.vue"),
        name: "批量导入商品审核",
      },
      {
        path: "/billAccount",
        component: () =>
          import("../views/accountChecking/billAccount/billAccount.vue"),
        name: "支付对账(明细)",
      },
      {
        path: "/unitReconcile",
        component: () =>
          import("../views/accountChecking/unitReconcile/unitReconcile.vue"),
        name: "单位对账列表",
      },
      {
        path: "/billingOrder",
        component: () =>
          import("../views/accountChecking/billingOrder/billingOrder.vue"),
        name: "订单账单列表",
      },
      {
        path: "/transactionFlow",
        component: () =>
          import("../views/accountChecking/transactionFlow/transactionFlow.vue"),
        name: "支付对账(订单)",
      },
      {
        path: "/carrierBill",
        component: () =>
          import("../views/accountChecking/carrierBill/carrierBill.vue"),
        name: "配送商账单",
      },
      {
        path: "/BillDetails",
        component: () =>
          import("../views/accountChecking/carrierBill/BillDetails.vue"),
        name: "账单明细",
      },
      {
        path: "/WhiteStatementlist",
        component: () =>
          import("../views/accountChecking/WhiteStatementlist/WhiteStatementlist.vue"),
        name: "白条对账单列表",
      },
      {
        path: "/accountStatement",
        component: () =>
          import("../views/accountChecking/accountStatement/accountStatement.vue"),
        name: "采购/销售对账单",
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (!getToken() && to.path != "/" && from.path != "/") {
    Message({
      message: "当前登录失效。请重新登录",
      type: "warning",
    });
    sessionStorage.removeItem("TOKEN");
    next({ path: "/" });
  }

  next();
});
export default router;
