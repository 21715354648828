import { GetAxios } from "../../../api/AxiosGet"; //get请求
import { PostAxios } from "../../../api/AxiosPost"; //post请求
import {
  userList,
  // userEdit,
  qualifyDetail,
  addressMsg,
  UsersaveAddress,
  scopeBusiness,
  getSalesman,
  getMultipleAccounts,
  resetAccountPassword,
  changeAccountMobile,
  getAccountShops,
  getUserInfoDetail,
  updateUserInfo,
  getMobileChangeLog,
  userSyncLog,
  modifyAccount,
  switchMemberStatus,
  getUserInvoice,
  updateUserInvoice
} from "../../../api/phpUrl"; //引入接口
import {
  userEdit,
  updateAccount,
  usergetPayType,
  userreplacePayType,
  businessscope,
  erpCodeMatch
} from "../../../api/javaUrl"; //引入接口
import { Message } from "element-ui"; //引入elmentui提示框
export default {
  namespaced: true,
  // 集中数据
  state: {
    // 会员列表
    userList: {},
    // 会员详情
    userInfo: {},
    // 会员资质详情
    userQualifyDetail: [],
    // 收货地址详情
    userAddressMsg: {},
    // 会员经营范围
    userScopeBusiness: {},
    // 业务员关系列表
    saleManList: [],
  },
  // 处理state中的数据
  mutations: {
    // 设置用户列表
    setUserList(state, data) {
      state.userList = data;
    },
    // 设置用户详情
    setUserEdit(state, data) {
      state.userInfo = data;
      state.userInfo.wldwid = data.erpCode;
    },
    // 设置会员资质详情
    setQualifyDetail(state, data) {
      state.userQualifyDetail = data;
    },
    // 设置会员收货地址
    setAddressMsg(state, data) {
      data.areaList = [
        data.province || "",
        data.city || "",
        data.district || "",
      ];
      state.userAddressMsg = data;
    },
    // 设置会员经营范围详情
    setScopeBusiness(state, data) {
      state.userScopeBusiness = data;
    },
    // 修改会员收货地址
    setUserAddress(state, data) {
      state.userAddressMsg.province = data[0];
      state.userAddressMsg.city = data[1];
      state.userAddressMsg.district = data[2];
      state.userAddressMsg.areaList = data;
    },
    // 设置业务员关系列表
    setSaleManList(state, data) {
      state.saleManList = data;
    },
  },
  // 发送异步请求
  actions: {
    // 用户类型列表
    async getUserList(context, params) {
      let { data } = await PostAxios(userList, params);
      context.commit("setUserList", data);
    },
    // 编辑会员详情(get为获取)
    async getUserEdit(context, params) {
      let data = await GetAxios(getUserInfoDetail, params);
      if (data.code == 200) {
        data.data.basicInfo.areaList = [
          data.data.basicInfo.provinceId || "",
          data.data.basicInfo.cityId || "",
          data.data.basicInfo.districtId || "",
        ];
        if (data.data.basicInfo.unitType == 0) {
          data.data.basicInfo.unitType = ''
        }
        console.log(data.data.basicInfo, 'data.data.basicInfo');
        context.commit("setUserEdit", data.data.basicInfo);
      }
    },
    //编辑-保存用户信息
    async postUserEdit(context, params) {
      let data = await PostAxios(updateUserInfo, params);
      //console.log(data);
      if (data.code == '000000') {
        // data.data.areaList = [
        //   data.data.province || "",
        //   data.data.city || "",
        //   data.data.district || "",
        // ];
        // context.commit("setUserEdit", data.data);
        Message({
          message: data.message,
          type: 'success'
        });
      } else {
        Message({
          message: data.message,
          type: 'warning'
        });
      }
    },
    // 修改账号
    async postupdateAccount(context, params) {
      return await PostAxios(updateAccount, params);
    },
    // 获取会员资质详情
    async getQualifyDetail(context, params) {
      let { data } = await GetAxios(qualifyDetail, params);
      context.commit("setQualifyDetail", data);
      return data;
    },
    // 获取会员收货地址
    async getAddressMsg(context, params) {
      let { data } = await GetAxios(addressMsg, params);
      data.areaList = [data.province, data.city, data.district];
      context.commit("setAddressMsg", data);
    },
    // 获取会员经营范围详情
    async getScopeBusiness(context, params) {
      let { data } = await GetAxios(scopeBusiness, params);
      context.commit("setScopeBusiness", data);
      return data;
    },
    // 修改会员收货信息
    // 获取会员业务员关系
    async getSalesmanList(context, params) {
      let { data } = await GetAxios(getSalesman, params);
      context.commit("setSaleManList", data);
    },
    // 修改收货地址
    async postUsersaveAddress(context, params) {
      return await PostAxios(UsersaveAddress, params);
    },
    async getusergetPayType(context, params) {
      return await GetAxios(usergetPayType, params);
      // context.commit("setSaleManList", data);
    },
    // 修改收货地址
    async postuserreplacePayType(context, params) {
      return await PostAxios(userreplacePayType, params);
    },
    // ERP经营范围手动推送
    async postbusinessscope(context, params) {
      return await PostAxios(businessscope + '?wldwid=' + params.wldwid, params);
    },
    // ERP经营范围手动推送
    async geterpCodeMatch(context, params) {
      return await GetAxios(erpCodeMatch, params);
    },
    // 修改账户
    async modifyAccount(context, params) {
      return await PostAxios(modifyAccount, params);
    },
    // 编辑-多店/账号列表
    async getMultipleAccounts(context, params) {
      return await GetAxios(getMultipleAccounts, params);
    },
    // 编辑-多店/账户列表-重置密码
    async resetAccountPassword(context, params) {
      return await PostAxios(resetAccountPassword, params);
    },
    // 编辑-多店/账号列表-修改手机号
    async changeAccountMobile(context, params) {
      return await PostAxios(changeAccountMobile, params);
    },
    // 编辑-多店/账号列表-绑定店铺列表
    async getAccountShops(context, params) {
      return await GetAxios(getAccountShops, params);
    },
    // 编辑-多店/账号列表-绑定店铺列表
    async getMobileChangeLog(context, params) {
      return await GetAxios(getMobileChangeLog, params);
    },
    // 编辑-多店/账号列表-绑定店铺列表
    async userSyncLog(context, params) {
      return await GetAxios(userSyncLog, params);
    },
    // 切换会员状态
    async switchMemberStatus(context, params) {
      return await PostAxios(switchMemberStatus, params);
    },
    // 会员列表-获取会员发票信息
    async getUserInvoice(context, params) {
      return await GetAxios(getUserInvoice, params);
    },
    // 会员列表-编辑会员发票信息
    async updateUserInvoice(context, params) {
      return await PostAxios(updateUserInvoice, params);
    },
  },
};
