import { GetAxios } from "../../../api/AxiosGet"; //get请求
import { PostAxios } from "../../../api/AxiosPost"; //post请求
import {
  statementWater,
  statementWaterExport,
  orderStatementList,
  getPaymentTypeList,//前端获取支付方式列表
  salesStatement,//销售对账单列表
  purchaseStatement,//采购对账单列表
  withdrawBillcancel,//作废账单
} from "../../../api/phpUrl"; //引入接口
import {
  billList,//账单列表
  addBillInfo,//添加付款
  collectionInformation,//账单收款信息
  billDetailsList,//收款明细列表
  billAmount,//收款明细金额相关
  configet,//获取供应商对公转账设置
} from "../../../api/javaUrl"; //引入接口

import { Message } from "element-ui"; //引入elmentui提示框
export default {
  namespaced: true,
  // 集中数据
  state: {

  },
  // 处理state中的数据
  mutations: {

  },
  // 发送异步请求
  actions: {
    async getBillList(context, params) {
      return await PostAxios(getBillList, params);
    },
    async returnOrderInfo(context, params) {
      return await GetAxios(returnOrderInfo, params);
    },
    async statementWater(context, params) {
      return await GetAxios(statementWater, params);
    },
    async statementWaterExport(context, params) {
      return await GetAxios(statementWaterExport, params);
    },
    async orderStatementList(context, params) {
      return await GetAxios(orderStatementList, params);
    },
    async billList(context, params) {
      return await PostAxios(billList, params);
    },
    async addBillInfo(context, params) {
      return await PostAxios(addBillInfo, params);
    },
    async collectionInformation(context, params) {
      return await GetAxios(collectionInformation, params);
    },
    async billDetailsList(context, params) {
      return await PostAxios(billDetailsList, params);
    },
    async billAmount(context, params) {
      return await GetAxios(billAmount, params);
    },
    async configet(context, params) {
      return await GetAxios(configet, params);
    },
    async getPaymentTypeList(context, params) {
      return await GetAxios(getPaymentTypeList, params);
    },
    async salesStatement(context, params) {
      return await PostAxios(salesStatement, params);
    },
    async purchaseStatement(context, params) {
      return await PostAxios(purchaseStatement, params);
    },
    async withdrawBillcancel(context, params) {
      return await PostAxios(withdrawBillcancel, params);
    },
  },
};
